import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";

function Refund() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Refund & Cancellation Policy",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container">
        <div className="section-60 text-justify">
          <b>
            <center>CANCELLATION POLICY</center>
          </b>
          <br />
          <br />
          Please note that once the payment gets deducted, it will be treated as
          successful transactions and there is no way User can cancel such
          transactions except in case of Insurance policies where user will get
          time period of 30 days as free look period to cancel their policy.
          <br />
          <br />
          <br />
          <b>
            <center>REFUND POLICY</center>
          </b>
          <br />
          <br />
          1. Amount once paid through the payment gateway shall not be refunded
          other than in the following circumstances:
          <br />
          - Multiple times debiting of Customer’s Card/Bank Account due to
          technical error OR Customer's account being debited with excess amount
          in a single transaction due to technical error. In such cases, excess
          amount excluding Payment Gateway charges would be refunded to the
          Customer.
          <br />
          - Due to technical error, payment being charged on the Customer’s
          Card/Bank Account but the transaction is unsuccessful. RV Capital does
          not provide refund & cancellation, thus we require payment Gateway to
          receive EMI from our customers.
          <br />
          - Health Insurance policies can be canceled during the free look
          period or after the free look period. The refund you receive depends
          on when you cancel the policy. Free look period. free look period is
          30 days from the policy start date. During this period, you can cancel
          the policy if you're not satisfied with the terms and conditions. You
          may receive a full refund of your premium, minus stamp duty and
          medical tests. After the free look period If you cancel after the free
          look period, you may receive a reduced refund. The refund amount
          depends on how long you've had the policy. For example, if you cancel
          within 1 month, you may receive 75% of your premium back. If you
          cancel after 6 months, you may not receive a refund.
          <br />
          <br />
          2. The Customer will have to make an application for refund along with
          the transaction number and original payment receipt if any generated
          at the time of making payments.
          <br />
          <br />
          3. The application in the prescribed format should be sent to
          customercare@rvcapitalfinance.com.
          <br />
          <br />
          4. The application will be processed manually and after verification,
          if the claim is found valid, the amount received in excess will be
          refunded by RV Capital through electronic mode in favor of the
          borrower and confirmation sent to the mailing address given to us,
          within a period of 7 calendar days on receipt of such claim. It will
          take 3-7 days for the money to show in your bank account depending on
          your bank’s policy.
          <br />
          <br />
          5. The company assumes no responsibility and shall incur no liability
          if it is unable to affect any Payment Instruction(s) on the Payment
          Date owing to any one or more of the following circumstances:
          <br />
          a. If the Payment Instruction(s) issued by you is/are incomplete,
          inaccurate, and invalid and delayed.
          <br />
          b. If the Payment Account has insufficient funds/limits to cover for
          the amount as mentioned in the Payment Instruction(s).
          <br />
          c. If the funds available in the Payment Account are under any
          encumbrance or charge. d. If your Bank refuses or delays honoring the
          Payment Instruction(s).
          <br />
          e. Circumstances beyond the control of Company (including, but not
          limited to, fire, flood, natural disasters, bank strikes, power
          failure, systems failure like computer or telephone lines breakdown
          due to an unforeseeable cause or interference from an outside force)
          <br />
          f. In case the payment is not effected for any reason, you will be
          intimated about the failed payment by an e-mail.
          <br />
          <br />
          6. User agrees that the Company, in its sole discretion, for any or no
          reason, and without penalty, may suspend or terminate his/her account
          (or any part thereof)or use of the Services and remove and discard all
          or any part of his/her account, user profile, or his/her recipient
          profile, at any time. Company may also in its sole discretion and at
          any time discontinue providing access to the Services, or any part
          thereof, with or without notice. User agrees that any termination of
          his/her access to the Services or any account he/she may have or
          portion thereof may be effected without prior notice and also agrees
          that Company will not be liable to User or any third party for any
          such termination. Any suspected, fraudulent, abusive, or illegal
          activity may be referred to appropriate law enforcement authorities.
          These remedies are in addition to any other remedies Company may have
          at law or in equity. Upon termination for any reason, the user agrees
          to immediately stop using the Services.
          <br />
          <br />
          7. The company may elect to resolve any dispute, controversy, or claim
          arising out of or relating to this Agreement or Service provided in
          connection with this Agreement by binding arbitration in accordance
          with the provisions of the Indian Arbitration & Conciliation Act,
          1996. Any such dispute, controversy or claim shall be arbitrated on an
          individual basis and shall not be consolidated in any arbitration with
          any claim or controversy of any other party.
          <br />
          <br />
          <br />
          <b>
            <center>CANCEL POLICY FOR CREDIT CARD</center>
          </b>
          <br />
          <br />
          You can cancel a credit card after applying and completing the
          approval process, but there are some important things to consider:
          <br />
          <br />
          <b>1. After Approval but Before Activation:</b>
          <br />
          If you’ve been approved for a credit card but haven’t activated it
          yet, you can typically call the issuer and request cancellation. This
          is the easiest way to cancel, as there’s no usage on the card, and it
          won’t affect your credit.
          <br />
          <br />
          <b>2. After Activation:</b>
          <br />
          If you’ve already activated the card and started using it, you can
          still cancel it, but there are some things to consider:
          <br />- <b>Balance</b>: If there's a balance on the card, you'll need
          to pay it off before it can be closed.
          <br />- <b>Impact on Credit Score</b>: Cancelling a credit card can
          impact your credit score, especially if it’s one of your older
          accounts or has a high credit limit. It can affect your credit
          utilization ratio, which plays a role in your credit score.
          <br />
          <br />
          <b>3. Fees:</b>
          <br />
          Make sure to check if there are any annual fees or other charges
          associated with the card. Some cards charge fees even if you don’t use
          them, so canceling after activation may save you from these recurring
          charges.
          <br />
          <br />
          <b>4. Process for Cancellation:</b>
          <br />
          - Contact your credit card issuer directly (via phone or online).
          <br />
          - Request the cancellation and confirm the process, ensuring you get
          written confirmation.
          <br />
          - Pay off any outstanding balance before requesting cancellation.
          <br />
          <br />
          <b>5. Credit Report:</b>
          <br />
          After cancellation, keep an eye on your credit report to ensure the
          account is properly marked as "closed" and that no further activity
          occurs.
          <br />
          <br />
          In short, it’s absolutely possible to cancel the card after you've
          applied, been approved, and even used it, but you’ll want to be
          mindful of any financial or credit implications. If you’re canceling
          because the card no longer fits your needs, make sure to weigh the
          pros and cons.
          <br />
          <br />
          <br />
          <b>
            <center>HEALTH INSURANCE PLANS</center>
          </b>
          <br />
          <br />
          Health Insurance offers a variety of health insurance plans with
          different coverage levels and premiums. The monthly premium will
          depend on factors such as the type of policy, the sum insured, the age
          of the policyholder, and any add-ons chosen.
          <br />
          <br />
          Here are some general ranges for the costs of Health Insurance plans:
          <br />
          <br />
          <b>1. MediCare Premier:</b>
          <br />- This is a comprehensive plan with coverage for
          hospitalization, pre and post-hospitalization, and critical illnesses.
          - The premium can range from <b>₹500 to ₹5,000</b> per month,
          depending on coverage and the age of the individual.
          <br />
          <br />
          <b>2. MediCare:</b>
          <br />- Offers basic health insurance coverage with hospitalization
          benefits. - The monthly premiums can range from <b>₹400 to ₹4,000</b>.
          <br />
          <br />
          <b>3. Critical Illness Insurance:</b>
          <br />- This type of policy provides coverage for life-threatening
          conditions such as cancer, heart attack, etc. - The premium typically
          starts from <b>₹1,000 to ₹3,000 per month</b>, depending on coverage.
          <br />
          <br />
          <b>4. Health Optima:</b>
          <br />- A family floater plan that covers multiple members in one
          policy. - Monthly premiums can range from <b>
            ₹1,500 to ₹12,000
          </b>{" "}
          depending on the sum insured and the number of family members covered.
          <br />
          <br />
          <b>5. Accident Care Plan:</b>
          <br />- Coverage for accidents, disability, and accidental death. -
          Premiums can range from <b>₹500 to ₹2,500 per month</b> depending on
          the sum insured.
          <br />
          <br />
          <b>Note:</b>
          <br /> These are only general estimates and the actual premiums can
          vary based on individual circumstances (age, medical history,
          location, etc.).
          <br />
          For the most accurate cost based on your needs, you should visit the
          website or contact their customer service for a personalized quote.
        </div>
      </div>
    </>
  );
}
export default Refund;
