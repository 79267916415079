import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import { getLoan, updateRef, updateStatus } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import celebration from "../Assets/img/ApplyLoan/celebration2.gif";

import pdf from "../Assets/img/pdf.png";
import Modal from "react-bootstrap/Modal";
import logo from "../Assets/img/logoLight.jpg";
import approve from "../Assets/img/LoanIcon/approved.png";
function ApplyNowInstant11(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();

  const [data, setData] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const downloadPdf = async (path, name) => {
    try {
      const response = await fetch(path, {
        headers: {
          "Content-Type": "application/pdf",
        },
      });
      if (!response.ok) {
        console.error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getLoan({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          loan_id: id,
        });
        if (response.status === 200) {
          setData(response.data);
          const timer = setTimeout(async () => {
            await downloadPdf(
              `${process.env.REACT_APP_API}/uploads/Loan/Agreement/${response.data.agreement}`,
              response.data.agreement.split("/").pop()
            );
          }, 1000);

          return () => clearTimeout(timer);
        }
      }
    };
    asyncFn();
  }, [id, logindata]);

  async function goHome() {
    navigate("/", { replace: true });
  }

  return (
    <ApplyInstantComponent background>
      <center>
        <h3>
          <center>Congratulations!!!</center>
        </h3>
      </center>
      <div class="contact-form">
        <div class="form-group mt-3">
          <center>
            <img src={approve} width="100" alt="" />

            <div
              className="card p-3 mt-30 mb-30"
              style={{
                backgroundColor: "#f2f4f6",
                textAlign: "center",
                maxWidth: "350px",
              }}
            >
              Loan Disbursement Amount
              <h3 className="mt-2">
                <b>₹{data.offered_amount - data.processing_fee}</b>
              </h3>
            </div>

            <p className="mt-30 mx-3">
              Your Personal Loan will be processed and disbursed shortly post internal approvals. You  download statement from{" "}
              <b
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  await downloadPdf(
                    `${process.env.REACT_APP_API}/uploads/Loan/Agreement/${data.agreement}`,
                    data?.agreement?.split("/").pop()
                  );
                }}
              >
                here.
              </b>
            </p>
            <button onClick={goHome} className="default-btn btn-sm mt-3">
              Go To Home
            </button>
          </center>
        </div>
      </div>
    </ApplyInstantComponent>
  );
}
export default ApplyNowInstant11;
