import logo from "../Assets/img/logoLight.jpg";
import instant from "../Assets/img/Loan/instant.png";
import { RWebShare } from "react-web-share";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import celebration from "../Assets/img/ApplyLoan/celebration3.gif";

function ApplyInstantComponent(props) {
  const [cssStyle, setCSSStyle] = useState({
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundImage: `url(${celebration}`,
  });
  const [background, setBackground] = useState(false);
  useEffect(() => {
    if (props.background) {
      setBackground(true);
    }
  }, []);

  return (
    <div class="container-fluid half-blue">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-10 col-12">
          <div className="card p-3 my-75" style={{ minHeight: "70vh" }}>
            <div
              style={background ? cssStyle : {backgroundImage: "unset"}}
            >
              <div className="d-flex justify-content-center">
                <Link to="/" replace>
                  <img src={logo} alt="logo" height={95} width={95} />
                </Link>
              </div>
              <div className="mt-30">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ApplyInstantComponent;
{
  /* <div className="row mt-2 d-flex justify-content-between">
        <div>
          <Link to="/">
            <img src={logo} alt="logo" height={95} width={95} />
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <RWebShare
            data={{
              //   text: "Like humans, flamingos make friends for life",
              url: "https://rvcapitalfinance.com/apply-now/Instant",
              title: "RV Capital Finance",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <button
              style={{ border: "2px solid #002d5b", borderRadius: "8px" }}
            >
              🔗
            </button>
          </RWebShare>
        </div>
        <div className="instant-company"> RV Capital Finance</div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 col-12">{props.children}</div>
        {/* <div
          className="col-lg-6 d-none d-lg-block position-fixed"
          style={{ right: "15px" }}
        >
          <div className="d-flex justify-content-center">
            <img
              src={instant}
              alt="instant loan"
              style={{ position: "sticky" }}
            />
          </div>
          <div className="instant-summary">
            <div className="row">
              <div className="col">
                <ul>
                  <li>Attractive Interest Rate</li>
                  <li>No Hidden Charges</li>
                </ul>
              </div>
              <div className="col">
                <ul>
                  <li>Quick Approvals</li>
                  <li>Zero Pre-payment Charges</li>
                </ul>
              </div>
            </div>
          </div>
        </div> 
      </div> */
}
