import { bankDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import {
  checkBank,
  validateACno,
  validateCAC,
  validateIFSC,
  validateSelect,
  validateText,
} from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Typeahead } from "react-bootstrap-typeahead";
import { allBankData } from "../Common/data";

function ProfileBank(props) {
  const { msg, onSubmit, logindata, setLogindata, profile, loan_id } = props;
  const [data, setData] = useState({});
  const [selectedBank, setSelectedBank] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  function onChangeData(e) {
    if (
      (e.target.name === "ac_no" || e.target.name === "c_ac_no") &&
      isNaN(e.target.value)
    ) {
      return false;
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value.toUpperCase(),
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response.status === 200) {
          if (response.data.bank[0]) {
            response.data.bank[0].c_ac_no = response.data.bank[0]?.ac_no;
            setData(response.data.bank[0]);
            setSelectedBank([response.data.bank[0].bank_name]);
          }
        }
      }
    };
    asyncFn();
  }, [logindata]);

  useEffect(() => {
    const asyncFn = async () => {
      if (data.ac_no && data.ifsc_code) {
        const response = await checkBank({
          bank_account: data?.ac_no,
          ifsc: data?.ifsc_code,
          id: getLocalStorage("rv-user")?.id,
        });
        setData((data) => ({
          ...data,
          bank_name: response?.bank_name?.toUpperCase(),
          branch_name: response?.branch_name?.toUpperCase(),
        }));
        if (response?.name === "ifsc" && response?.error) {
          setErrors((errors) => ({
            ...errors,
            ifsc_code: response?.error || "",
          }));
        } else if (response?.name === "bank_account" && response?.error) {
          setErrors((errors) => ({
            ...errors,
            ac_no: response?.error || "",
          }));
        } else if (response?.error) {
          console.log("error==", response?.error);
          
          setErrors((errors) => ({
            ...errors,
            ifsc_code: response?.error || "",
          }));
        }
      }
    };

    asyncFn();
  }, [data.ac_no, data.ifsc_code]);

  function validateAC(str) {
    setErrors((errors) => ({
      ...errors,
      ac_no: validateACno(str).error,
    }));
  }
  function validateC_AC(str, ac_no) {
    setErrors((errors) => ({
      ...errors,
      c_ac_no: validateCAC(str, ac_no).error,
    }));
  }
  function validateType(str) {
    setErrors((errors) => ({
      ...errors,
      ac_type: validateSelect(str, "account type").error,
    }));
  }

  function validateBankName(str) {
    setErrors((errors) => ({
      ...errors,
      bank_name: validateText(str, "bank name").error,
    }));
  }
  function validateBranchName(str) {
    setErrors((errors) => ({
      ...errors,
      branch_name: validateText(str, "branch name").error,
    }));
  }
  function validateIfsc(str) {
    setErrors((errors) => ({
      ...errors,
      ifsc_code: validateIFSC(str).error,
    }));
  }

  function validateForm() {
    validateAC(data.ac_no);
    {
      !profile && validateC_AC(data.c_ac_no, data.ac_no);
    }
    validateBankName(data.bank_name);
    validateBranchName(data.branch_name);
    validateIfsc(data.ifsc_code);
    validateType(data.ac_type);
    let valid =
      validateACno(data.ac_no).valid &&
      validateText(data.bank_name, "bank name").valid &&
      validateText(data.branch_name, "branch name").valid &&
      validateIFSC(data.ifsc_code).valid &&
      validateSelect(data.ac_type, "account type").valid &&
      (!profile ? validateCAC(data.c_ac_no, data.ac_no).valid : true);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {      
      const payload = {
        id: getLocalStorage("rv-user")?.id,
        ac_no: data.ac_no,
        ac_type: data.ac_type,
        ifsc_code: data.ifsc_code,
        bank_name: data.bank_name,
        branch_name: data.branch_name,
        ...(loan_id ? { loan_id: loan_id } : {}),
      };
      const response = await bankDetails({
        token: logindata.accessToken || getLocalStorage("rv-user")?.accessToken,
        data: payload,
      });
      if (response?.data?.status === 422 || response?.status !== 200) {
        Swal.fire(
          "Oops!",
          response.data.data.message || response.data.message,
          "error"
        );
      } else {
        if (response?.data?.data?.bank_name === "NOT_AVAILABLE") {
          setErrors((errors) => ({
            ...errors,
            ifsc_code: "IFSC code is not proper",
          }));
        } else {
          onSubmit("success");
        }
      }
    }
  }

  return (
    <>
      <div class="form-group">
        <label className="label-login">Account No.: </label>
        <input
          type="text"
          name="ac_no"
          value={data.ac_no}
          class="form-control w-100"
          onBlur={() => validateAC(data.ac_no)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.ac_no && (
          <div class="help-block with-errors">{errors.ac_no}</div>
        )}
      </div>
      {!profile && (
        <div class="form-group">
          <label className="label-login">Confirm Account No.: </label>
          <input
            type="text"
            name="c_ac_no"
            value={data.c_ac_no}
            class="form-control w-100"
            onBlur={() => validateC_AC(data.c_ac_no, data.ac_no)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />
          {errors.c_ac_no && (
            <div class="help-block with-errors">{errors.c_ac_no}</div>
          )}
        </div>
      )}
      <div class="form-group">
        <label className="label-login">Account Type: </label>
        <select
          name="ac_type"
          // value={data.ac_type}
          onChange={(e) => {
            onChangeData(e);
          }}
          class="form-control w-100"
          onBlur={() => validateType(data.ac_type)}
        >
          <option value="">SELECT ACCOUNT TYPE</option>
          {["SAVING", "CURRENT"].map((o) => {
            return (
              <option value={o} selected={data.ac_type === o}>
                {o}
              </option>
            );
          })}
        </select>
        {errors.ac_type && (
          <div class="help-block with-errors">{errors.ac_type}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">IFSC Code: </label>
        <input
          type="text"
          name="ifsc_code"
          value={data.ifsc_code}
          class="form-control w-100"
          onBlur={() => validateIfsc(data.ifsc_code)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.ifsc_code && (
          <div class="help-block with-errors">{errors.ifsc_code}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">Bank Name: </label>
        {/* <Typeahead
          class="form-control w-100"
          id="bank_name"
          name="bank_name"
          labelKey="bank_name"
          onChange={setSelectedBank}
          options={allBankData}
          selected={selectedBank}
          onBlur={() => validateBankName(selectedBank[0])}
        /> */}
        <input
          disabled
          type="text"
          name="bank_name"
          value={data.bank_name}
          class="form-control w-100"
          // onBlur={() => validateIfsc(data.ifsc_code)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.bank_name && (
          <div class="help-block with-errors">{errors.bank_name}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">Branch Name: </label>
        <input
          disabled
          type="text"
          name="branch_name"
          value={data.branch_name}
          class="form-control w-100"
          onBlur={() => validateBranchName(data.branch_name)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.branch_name && (
          <div class="help-block with-errors">{errors.branch_name}</div>
        )}
      </div>
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}
export default ProfileBank;
