import { useEffect, useState } from "react";
import Footer from "../Components/footer";
import Menu from "../Components/menu";
import TopHeader from "../Components/topHeader";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  getCustomerPersonalLoans,
} from "../api/customer";
import { getLocalStorage } from "../Common/cookie";
import { Link } from "react-router-dom";
import cibil from "../Assets/img/Banner/scoreGIF.gif";
import loan from "../Assets/img/Loan/instantIcon.png";

function Layout(props) {
  const [id, setId] = useState(null);
  const [link, setLink] = useState("");
  const [loanStatus, setLoanStatus] = useState("status_name");
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => {
    if (visible === false) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      if (props.logindata) {
        const checkCookie = getLocalStorage("rv-user");
        const response = await getCustomerPersonalLoans({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response.status === 200) {
          const filteredData = response.data.filter(
            (item) => ((item?.verify_pending || item?.status_name) && item?.status_name !== "Disbursed" && item?.status_name !== "Rejected")
          );
          if (filteredData) {
            let token = "";
            const modalShown = sessionStorage.getItem("modalShown");
            setId(filteredData?.[0]?.id);            
            const status_name =
              filteredData?.[0]?.verify_pending ||
              filteredData?.[0]?.status_name;
            await filteredData[0]?.status?.map((s) => {
              if (s.name === status_name) {
                token = s.token;
              }
            });
            // setDate(response.data?.[0]?.updated_at);
            if (filteredData?.[0]?.verify_pending) {
              setLoanStatus("verify_pending");
            }
            await checkNext(
              filteredData?.[0]?.id,
              status_name,
              token,
              filteredData?.[0]?.verify_pending
                ? "verify_pending"
                : "status_name"
            );
            if (!modalShown) {
              sessionStorage.setItem("modalShown", "true");
              setVisible(true);
              const timer = setTimeout(() => {
                setVisible(false);
              }, 3000);
              return () => clearTimeout(timer);
            }
          }
        }
      }
    };
    asyncFn();
  }, [props.logindata]);

  const checkNext = (id, status, token, status_pending) => {
    if (status === "Customer Data Verification") {
      let url =
        "/apply-now/Instant/Basics?id=" +
        id +
        (status_pending === "verify_pending" ? `&token=${token}` : "");
      setLink(url);
    } else if (status === "Address Verification") {
      let url =
        "/apply-now/Instant/address?id=" +
        id +
        (status_pending === "verify_pending" ? `&token=${token}` : "");
      setLink(url);
    } else if (status === "Occupation Verification") {
      if (status_pending === "verify_pending") {
        let url =
          "/apply-now/Instant/professional?id=" + id + `&token=${token}`;
        setLink(url);
      } else {
        let url = "/apply-now/Instant/category?id=" + id;
        setLink(url);
      }
    } else if (status === "Selfie Upload") {
      let url =
        "/apply-now/Instant/selfie?id=" +
        id +
        (status_pending === "verify_pending" ? `&token=${token}` : "");
      setLink(url);
    } else if (status === "e-KYC") {
      let url =
        "/apply-now/Instant/kyc?id=" +
        id +
        (status_pending === "verify_pending" ? `&token=${token}` : "");
      setLink(url);
    } else if (status === "Bank Details Verification") {
      let url =
        "/apply-now/Instant/bank?id=" +
        id +
        (status_pending === "verify_pending" ? `&token=${token}` : "");
      setLink(url);
    } else if (status === "e-Sign") {
      let url =
        "/apply-now/Instant/review?id=" +
        id +
        (status_pending === "verify_pending" ? `&token=${token}` : "");
      setLink(url);
    }
  };
  return (
    <>
      <header class="header-area">
        <TopHeader />
        <Menu
          logindata={props.logindata}
          setLogindata={props.setLogindata}
          authRequire={props?.authRequire ? props.authRequire : false}
        />
      </header>
      {props.logindata && (
        <div className={`reminder ${visible ? "visible" : "hidden"}`}>
          <div className="reminder-toggle" onClick={toggleVisibility}>
            {visible ? (
              <i class="fas fa-chevron-right"></i>
            ) : (
              <i class="fas fa-chevron-left"></i>
            )}
          </div>
          <div className="reminder-content">
            <Link to="/">
              <div className="free-cibil d-flex">
                <div className="d-flex align-items-center">
                  <img
                    src={cibil}
                    alt="alt"
                    style={{ minWidth: "50px", height: "40px", width: "50px" }}
                  />
                </div>
                <div className="d-flex align-items-center ml-2">
                  <h6 className="mb-0">
                    <b>
                      Get Credit Score for{" "}
                      <span style={{ color: "red" }}>FREE!!!</span> 😍
                    </b>
                  </h6>
                </div>
              </div>
            </Link>
            {/* {id && (
              <Link to={link}>
                <div>
                  <h6 className="mt-30 text-left">
                    <b>Recent Activity</b>
                  </h6>
                  <div className="loan-card d-flex">
                    <div className="d-flex align-items-center">
                      <img
                        src={loan}
                        alt="alt"
                        style={{
                          minWidth: "50px",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center ml-2">
                      <div>
                        <h6 className="mb-0">
                          <b>Personal Loan</b>
                        </h6>
                        <div
                          className="mt-1"
                          style={{ fontSize: "13px", color: "#000" }}
                        >
                          {loanStatus === "verify_pending" ? (
                            <>Resubmit your data to countinue your loan process.</>
                          ) : (
                            <>
                              Exciting Offers are waiting for you. Let's
                              countinue from where you left.
                            </>
                          )}

                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )} */}
          </div>
        </div>
      )}
      {props.children}
      <Footer />
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={2500}
        pauseOnFocusLoss={false}
        hideProgressBar
        draggable
        closeOnClick
        transition={Slide}
      />
    </>
  );
}

export default Layout;
