import { useEffect, useState } from "react";
import { getLocalStorage } from "../Common/cookie";
import { getLoan } from "../api/loan";

function ReviewLoan(props) {
  const { msg, onSubmit, logindata, loandata } = props;
  const [data, setData] = useState({});
  const [bank, setBank] = useState(null);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getLoan({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          loan_id: loandata,
        });
        if (response.status === 200) {
          setData(response.data);
          setBank(response.data?.customer?.bank?.[0]?.ac_no);
        }
      }
    };
    asyncFn();
  }, [loandata, logindata]);

  const saveData = () => {
    onSubmit("success");
  };

  return (
    <>
      <div className="card p-3" style={{backgroundColor: "#f2f4f6"}}>
        <table className="mb-0">
          <tr>
            <td>Sanctioned Amount</td>
            <td style={{ textAlign: "right" }}>₹{data.offered_amount}</td>
          </tr>
          <tr>
            <td>Processing Fee</td>
            <td style={{ textAlign: "right" }}>
             - ₹{data.processing_fee ? data.processing_fee : "0"}
            </td>
          </tr>
          <tr style={{borderTop: "1px solid"}}>
            <td><b>Net disbursal amount</b></td>
            <td style={{ textAlign: "right" }}>
              <b>₹{data.offered_amount - data.processing_fee}</b>
            </td>
          </tr>
        </table>
      </div>
      <div className="card p-3 mt-30" style={{backgroundColor: "#f2f4f6"}}>
        {data.offered_amount > 5000 ? (
          <table className="mb-0">
            <tr>
              <td>
                Monthly Installment
                <br />
                <b>₹{data.emi_amount ? data.emi_amount : "0"}</b>
                <br />
                <span style={{ fontSize: "12px" }}>
                  5<sup>st</sup> of every month
                </span>
              </td>
              <td>
                Interest Rate
                <br />
                <b>{data.offered_interest ? data.offered_interest : "0"}%</b>
              </td>
            </tr>
            <tr>
              <td>
                Tenure
                <br />
                <b>
                  {data.offered_tenure} {data.offered_tenure_in}
                </b>
              </td>
              <td>
                Bank Account
                <br />
                <b>**** **** **** {bank ? bank.toString().slice(-4) : ""}</b>
              </td>
            </tr>
          </table>
        ) : (
          <table className="mb-0">
            <tr>
              <td>
                Repayment Amount
                <br />
                <b>{`₹${
                  Number(data.offered_amount) + Number(data.offered_interest)
                }*`}</b>
              </td>
              <td>
                Interest Rate
                <br />
                <b>₹{data.offered_interest}</b>
              </td>
            </tr>
            <tr>
              <td>
                Tenure
                <br />
                <b>{new Date(data.offered_tenure).toLocaleDateString()}</b>
              </td>
              <td>
                Bank Account
                <br />
                <b>**** **** **** {bank ? bank.toString().slice(-4) : ""}</b>
              </td>
            </tr>
          </table>
        )}
      </div>
      <div style={{margin:"30px 0"}}>
      <span>
        To proceed with your loan application, we require your agreement on the
        below concerns.
        <br />
        By clicking “Agree and Continue,” you confirm that you:
        <br />
        1. Have reviewed and understood the terms and conditions of the loan.
        <br />
        2. Agree to provide a e-signature to complete the application process.
      </span>
      </div>
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled mt-2"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}
export default ReviewLoan;
