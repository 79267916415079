import { BrowserRouter, Routes, Route } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import logo from "../Assets/img/logoLight.jpg";
import Layout from "../Components/layout";
import Error from "./error";
import Home from "./home";
import About from "./about";
import Contact from "./contact";
import ApplyNow from "./applyNow";
import ApplyNowTypes from "./applyNowTypes";
import InstantLoan from "./instantLoan";
import StudentLoan from "./studentLoan";
import BusinessLoan from "./bussinessLoan";
import EducationLoan from "./educationLoan";
import MachinaryLoan from "./machinaryLoan";
import HomeLoan from "./homeLoan";
import LoanAgainstProperty from "./loanAgaintProperty";
import ProfessionalLoan from "./professionalLoan";
import CarLoan from "./carLoan";
import QuickPay from "./quickPay";
import Profile from "./profile";
import Loans from "./loans";
import Insurances from "./insurances";
import ApplyNowInstant from "./applyNowInstant";
import ApplyNowBusiness from "./applyNowBusiness";
import ApplyNowEdu from "./applyNowEdu";
import ApplyNowMachinary from "./applyNowMachinary";
import ApplyNowHome from "./applyNowHome";
import ApplyNowAgainstProperty from "./applyNowAgainstProperty";
import ApplyNowProfessional from "./applyNowProfessional";
import ApplyNowCar from "./applyNowCar";
import ApplyNowStudent from "./applyNowStudent";
import LifeInsurance from "./lifeInsurance";
import HealthInsurance from "./healthInsurance";
import CarInsurance from "./carInsurance";
import BikeInsurance from "./bikeInsurance";
import ApplyInsuranceCar from "./applyInsuranceCar";
import ApplyInsuranceBike from "./applyInsuranceBike";
import ApplyInsuranceHealth from "./applyInsuranceHealth";
import ApplyInsuranceLife from "./applyInsuranceLife";
import AxisCC from "./axisCC";
import HdfcCC from "./hdfcCC";
import IcicCC from "./iciciCC";
import IndusindCC from "./indusindCC";
import ApplyCCAxis from "./applyCCAxis";
import ApplyCCHdfc from "./applyCCHdfc";
import IDFCCC from "./idfcCC";
import ApplyCCIdfc from "./applyCCIdfc";
import ApplyCCICICI from "./applyCCICICI";
import ApplyCCIndusind from "./applyCCIndusind";
import SCCC from "./standardCharteredCC";
import ApplyCCSC from "./applyCCSC";
import Policy from "./privacyPolicy";
import Terms from "./terms";
import Disclaimer from "./disclaimer";
import { useEffect, useState } from "react";
import io from "socket.io-client";
import ApplyNowInstant2 from "./applyNowInstant2";
import ApplyNowInstant3 from "./applyNowInstant3";
import ApplyNowInstant4 from "./applyNowInstant4";
import ApplyNowInstant5 from "./applyNowInstant5";
import ApplyNowInstant6 from "./applyNowInstant6";
import ApplyNowInstant7 from "./applyNowInstant7";
import ApplyNowInstant8 from "./applyNowInstant8";
import LoginForm from "../Components/login";
import { getLocalStorage } from "../Common/cookie";
import ApplyNowInstant9 from "./applyNowInstant9";
import ApplyNowInstant10 from "./applyNowInstant10";
import ApplyNowInstant11 from "./applyNowInstant11";
import ApplyNowCC from "./applyNowCC";
import ApplyNowInstantBanner from "./applyNowInstantBanner";
import Refund from "./refund";
import LoanDetails from "./loanDetails";
import LoanFromBank from "./loanFromBank";
import PersonalLoanFromBank from "./personalLoanFromBank";
import BusinessLoanFromBank from "./businessoanFromBank";
import BirlaPersonalLoan from "./birlaPersonalLoan";
import ApplyNowBirlaPersonal from "./applyNowBirlaPersonal";
import IndusindPersonalLoan from "./indusindPersonalLoan";
import SmfcPersonalLoan from "./smfcPersonalLoan";
import CreditVidyaPersonalLoan from "./creditVidyaPersonalLoan";
import IiflBusinessLoan from "./iiflBusinessLoan";
import BirlaUdyogBusinessLoan from "./birlaUdyogBusinessLoan";
import ApplyNowIndusindPersonal from "./applyNowIndusindPersonal";
import ApplyNowSmfcPersonal from "./applyNowSmfcPersonal";
import ApplyNowCreditVidyaPersonal from "./applyNowCreditVidyaPersonal";
import ApplyNowIiflBusiness from "./applyNowIiflBusiness";
import ApplyNowBirlaUdyogBusiness from "./applyNowBirlaUdyogBusiness";
import FibePersonalLoan from "./fibePersonalLoan";
import BajajPersonalLoan from "./bajajPersonalLoan";
import ApplyNowFibePersonal from "./applyNowFibePersonal";
import ApplyNowBajajPersonal from "./applyNowBajajPersonal";
import ApplyNowInstantPan from "./applyNowInstantPan";
import ApplyNowInstantOccCat from "./applyNowInstantOccCat";
import ApplyNowInstantStatement from "./applyNowInstantStatement";
import ApplyNowInstantSelfie from "./applyNowInstantSelfie";
const socket = io(process.env.REACT_APP_SOCKET);

export default function App() {
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const [logindata, setLogindata] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/home"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <About />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <Contact />
            </Layout>
          }
        />
        <Route
          path="/apply-now"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowTypes />
            </Layout>
          }
        />
        <Route
          path="/apply-cc"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowCC />
            </Layout>
          }
        />
        <Route
          path="/apply-now/Instant"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstantBanner
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/Pan"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstantPan
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/Basics"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/address"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant5
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/category"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstantOccCat
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/professional"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant2
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/request"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant3
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/offered"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant9
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />

        <Route
          path="/apply-now/Instant/kyc"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant4
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/selfie"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstantSelfie
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/bank"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant6
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/bank-statement"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstantStatement
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/review"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant7
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        {/*   <Route
          path="/apply-now/Instant/reference"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant8
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Instant/docs"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant10
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />*/}
        <Route
          path="/apply-now/Instant/thanks"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <ApplyNowInstant11
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </LoginModal>
          }
        />
        <Route
          path="/apply-now/Business"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowBusiness
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/Education"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowEdu
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/Machinary"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowMachinary
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/Home"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowHome
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/AgainstProperty"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowAgainstProperty
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/Professional"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowProfessional
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/Car"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowCar
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/Student"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowStudent
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/AdityaBirlaPersonal"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowBirlaPersonal
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/IndusIndPersonal"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowIndusindPersonal
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/SMFCPersonal"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowSmfcPersonal
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/CreditVidyaPersonal"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowCreditVidyaPersonal
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/FibePersonal"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowFibePersonal
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/BajajPersonal"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowBajajPersonal
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/IIFLBusiness"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowIiflBusiness
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-now/BirlaUdyogBusiness"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyNowBirlaUdyogBusiness
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-insurance/Life"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyInsuranceLife
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-insurance/Health"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyInsuranceHealth
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-insurance/Car"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyInsuranceCar
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-cc/Axis"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyCCAxis
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-cc/HDFC"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyCCHdfc
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-cc/IDFC"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyCCIdfc
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-cc/ICICI"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyCCICICI
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-cc/Indusind"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyCCIndusind
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/apply-cc/StandardCharted"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ApplyCCSC
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/instant-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <InstantLoan />
            </Layout>
          }
        />
        <Route
          path="/student-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <StudentLoan />
            </Layout>
          }
        />
        <Route
          path="/business-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <BusinessLoan />
            </Layout>
          }
        />
        <Route
          path="/education-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <EducationLoan />
            </Layout>
          }
        />
        <Route
          path="/machinary-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <MachinaryLoan />
            </Layout>
          }
        />
        <Route
          path="/home-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <HomeLoan />
            </Layout>
          }
        />
        <Route
          path="/life-insurance"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <LifeInsurance />
            </Layout>
          }
        />
        <Route
          path="/health-insurance"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <HealthInsurance />
            </Layout>
          }
        />
        <Route
          path="/car-insurance"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <CarInsurance />
            </Layout>
          }
        />
        {/*    <Route
          path="/bike-insurance"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <BikeInsurance />
            </Layout>
          }
        /> */}
        <Route
          path="/against-property-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <LoanAgainstProperty />
            </Layout>
          }
        />
        <Route
          path="/professional-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <ProfessionalLoan />
            </Layout>
          }
        />
        <Route
          path="/loan-from-bank"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <LoanFromBank />
            </Layout>
          }
        />
        <Route
          path="/personal-loan-from-bank"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <PersonalLoanFromBank />
            </Layout>
          }
        />
        <Route
          path="/business-loan-from-bank"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <BusinessLoanFromBank />
            </Layout>
          }
        />
        <Route
          path="/instant/indusind"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <IndusindPersonalLoan />
            </Layout>
          }
        />
        <Route
          path="/instant/aditya-birla"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <BirlaPersonalLoan />
            </Layout>
          }
        />
        <Route
          path="/instant/smfg"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <SmfcPersonalLoan />
            </Layout>
          }
        />
        <Route
          path="/instant/credit-vidya"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <CreditVidyaPersonalLoan />
            </Layout>
          }
        />
        <Route
          path="/instant/fibe"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <FibePersonalLoan />
            </Layout>
          }
        />
        <Route
          path="/instant/bajaj"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <BajajPersonalLoan />
            </Layout>
          }
        />
        <Route
          path="/instant/iifl"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <IiflBusinessLoan />
            </Layout>
          }
        />
        <Route
          path="/instant/aditya-birla-udyog"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <BirlaUdyogBusinessLoan />
            </Layout>
          }
        />
        <Route
          path="/car-loan"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <CarLoan />
            </Layout>
          }
        />
        <Route
          path="/quick-pay"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <QuickPay
                socket={socket}
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
              />
            </Layout>
          }
        />
        <Route
          path="/axis-cc"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <AxisCC />
            </Layout>
          }
        />
        <Route
          path="/hdfc-cc"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <HdfcCC />
            </Layout>
          }
        />
        <Route
          path="/idfc-cc"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <IDFCCC />
            </Layout>
          }
        />
        <Route
          path="/icici-cc"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <IcicCC />
            </Layout>
          }
        />
        <Route
          path="/indusind-cc"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <IndusindCC />
            </Layout>
          }
        />
        <Route
          path="/standard-chartered-cc"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <SCCC />
            </Layout>
          }
        />
        <Route
          path="/profile"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <Layout
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
                authRequire
              >
                <Profile logindata={logindata} setLogindata={setLogindata} />
              </Layout>
            </LoginModal>
          }
        />
        <Route
          path="/loans"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <Layout
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
                authRequire
              >
                <Loans
                  socket={socket}
                  logindata={logindata}
                  setLogindata={(e) => setLogindata(e)}
                />
              </Layout>
            </LoginModal>
          }
        />
        {/*  <Route
          path="/loan-details/:id"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <Layout
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
                authRequire
              >
                <LoanDetails socket={socket}
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)} />
              </Layout>
            </LoginModal>
          }
        />
        <Route
          path="/insurances"
          element={
            <LoginModal
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)}
            >
              <Layout
                logindata={logindata}
                setLogindata={(e) => setLogindata(e)}
                authRequire
              >
                <Insurances socket={socket}
              logindata={logindata}
              setLogindata={(e) => setLogindata(e)} />
              </Layout>
            </LoginModal>
          }
        />*/}
        <Route
          path="/policy"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <Policy />
            </Layout>
          }
        />
        <Route
          path="/terms"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <Terms />
            </Layout>
          }
        />
        <Route
          path="/refund"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <Refund />
            </Layout>
          }
        />
        <Route
          path="/disclaimer"
          element={
            <Layout logindata={logindata} setLogindata={(e) => setLogindata(e)}>
              <Disclaimer />
            </Layout>
          }
        />
        <Route path="/*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

const LoginModal = ({ logindata, setLogindata, children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const checkCookie = getLocalStorage("rv-user");
    if (!checkCookie) {
      handleShow();
    }
  }, []);

  function handleLoginDetails() {}

  return (
    <>
      {children}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <div>
              <img src={logo} alt="" width="100" />
              {/* <div className='text-center login-heading' >Login</div> */}
            </div>
          </div>
          <div className="section d-flex justify-content-center">
            <form style={{ width: "260px" }}>
              <LoginForm
                setShow={(e) => setShow(e)}
                setLogindata={(e) => setLogindata(e)}
                onSubmit={handleLoginDetails}
                message="Login"
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
