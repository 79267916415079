import { professionalDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import {
  validateEmail,
  validatePin,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { getLocation } from "../api/location";
import { useNavigate } from "react-router-dom";

function ProfileProfessional(props) {
  const { msg, onSubmit, logindata, profile, type_name, loan_id } = props;
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  function onChangeData(e) {
    if (e.target.name === "c_pincode") {
      if (isNaN(e.target.value)) {
        return false;
      } else {
        setLocation(e.target.value);
      }
    }
    if (e.target.name === "income" && isNaN(e.target.value)) {
      return false;
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value.toUpperCase()
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response.status === 200) {
          let occdata = {...response.data, ...response.data.occupation[0]}
          occdata.occupation_type = type_name
          delete occdata.occupation
          delete occdata.bank
          delete occdata.address
          delete occdata.doc
          delete occdata.loan
          delete occdata.personal_loan
          delete occdata.bank_personal_loan
          setData(occdata);
          if (occdata.c_pincode) {
            await setLocation(occdata.c_pincode);
          }
        }
      }
    };
    asyncFn();
  }, [logindata]);

  const setLocation = async (c_pincode) => {
    if (c_pincode && c_pincode.length === 6) {
      const location = await getLocation({
        pincode: c_pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setData((data) => ({
          ...data,
          c_city: lData.District.toUpperCase(),
          c_state: lData.State.toUpperCase(),
          c_country: lData.Country.toUpperCase()
        }));
        setErrors((errors) => ({
          ...errors,
          c_pincode: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          c_pincode: "Please enter valid Pin Code",
        }));
      }
    }
  };

  function validateType(str) {
    setErrors((errors) => ({
      ...errors,
      occupation_type: validateSelect(str, "occupation type").error,
    }));
  }
  function validateCompanyName(str) {
    setErrors((errors) => ({
      ...errors,
      c_name: validateText(str, "company name").error,
    }));
  }
  function validateCompanyEmail(str) {
    setErrors((errors) => ({
      ...errors,
      c_email: validateEmail(str).error,
    }));
  }
  function validateCompanyAddress(str) {
    setErrors((errors) => ({
      ...errors,
      c_address: validateText(str, "company address").error,
    }));
  }
  function validateCompanyPincode(str) {
    setErrors((errors) => ({
      ...errors,
      c_pincode: validatePin(str).error,
    }));
  }
  function validateIncome(str) {
    setErrors((errors) => ({
      ...errors,
      income: validateSalary(str, "income").error,
    }));
  }

  function validateForm() {
    validateType(data.occupation_type);
    validateCompanyName(data.c_name);
    validateIncome(data.income);
    if (data.occupation_type === "Salaried") {
      validateCompanyEmail(data.c_email);
      validateCompanyAddress(data.c_address);
      validateCompanyPincode(data.c_pincode);
    }
    let valid =
      validateSelect(data.occupation_type, "occupation type").valid &&
      validateText(data.c_name, "company name").valid &&
      validateSalary(data.income, "income").valid &&
      (data.occupation_type === "Salaried"
        ? validateEmail(data.c_email).valid &&
          validateText(data.c_address, "company address").valid &&
          validatePin(data.c_pincode).valid
        : true);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (!errors.c_pincode) {
      if (validateForm()) {
        const response = await professionalDetails({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          data: {
            customer_id: data.id || getLocalStorage("rv-user")?.id,
            occupation_type: data.occupation_type,
            income: data.income,
            c_name: data.c_name,
            c_email: data.c_email,
            c_address: data.c_address,
            c_pincode: data.c_pincode,
            c_city: data.c_city,
            c_state: data.c_state,
            c_country: data.c_country,
            ...(loan_id ? {loan_id: loan_id} : {})
          },
        });
        if (response.status === 200) {
          onSubmit("success");
        }
      }
    }
  }

  return (
    <>
      {!type_name && (
        <div class="form-group">
          <label className="label-login">Occupation Type: </label>
          <br />
          <input
            type="radio"
            name="occupation_type"
            value="Salaried"
            checked={data.occupation_type === "Salaried"}
            onBlur={() => validateType(data.occupation_type)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />{" "}
          Salaried
          <input
            style={{ marginLeft: "30px" }}
            type="radio"
            name="occupation_type"
            value="Non Salaried"
            checked={data.occupation_type === "Non Salaried"}
            onBlur={() => validateType(data.occupation_type)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />{" "}
          Self Employed
          {errors.occupation_type && (
            <div class="help-block with-errors">{errors.occupation_type}</div>
          )}
        </div>
      )}
      {data.occupation_type === "Salaried" ? (
        <>
          <div class="form-group">
            <label className="label-login">Monthly Salary: </label>
            <input
              type="text"
              name="income"
              value={data.income}
              class="form-control w-100"
              onBlur={() => validateIncome(data.income)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.income && (
              <div class="help-block with-errors">{errors.income}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Name: </label>
            <input
              type="text"
              name="c_name"
              value={data.c_name}
              class="form-control w-100"
              onBlur={() => validateCompanyName(data.c_name)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.c_name && (
              <div class="help-block with-errors">{errors.c_name}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Email: </label>
            <input
              type="text"
              name="c_email"
              value={data.c_email}
              class="form-control w-100"
              onBlur={() => validateCompanyEmail(data.c_email)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.c_email && (
              <div class="help-block with-errors">{errors.c_email}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Address: </label>
            <input
              type="text"
              name="c_address"
              value={data.c_address}
              class="form-control w-100"
              onBlur={() => validateCompanyAddress(data.c_address)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.c_address && (
              <div class="help-block with-errors">{errors.c_address}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Pin Code: </label>
            <input
              type="text"
              name="c_pincode"
              value={data.c_pincode}
              class="form-control w-100"
              onBlur={() => validateCompanyPincode(data.c_pincode)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.c_pincode && (
              <div class="help-block with-errors">{errors.c_pincode}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization City: </label>
            <input
              type="text"
              name="c_city"
              class="form-control w-100"
              value={data.c_city}
              disabled
            />
          </div>
          <div class="form-group">
            <label className="label-login">Organization State: </label>
            <input
              type="text"
              name="c_state"
              class="form-control w-100"
              value={data.c_state}
              disabled
            />
          </div>
        </>
      ) : (
        <>
          <div class="form-group">
            <label className="label-login">Yearly Revenue: </label>
            <input
              type="text"
              name="income"
              value={data.income}
              class="form-control w-100"
              onBlur={() => validateIncome(data.income)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.income && (
              <div class="help-block with-errors">{errors.income}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Company Name: </label>
            <input
              type="text"
              name="c_name"
              value={data.c_name}
              class="form-control w-100"
              onBlur={() => validateCompanyName(data.c_name)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.c_name && (
              <div class="help-block with-errors">{errors.c_name}</div>
            )}
          </div>
        </>
      )}
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}
export default ProfileProfessional;
