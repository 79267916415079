import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import pdf from "../Assets/img/pdf.png";
import { getLocalStorage } from "../Common/cookie";
import { getLoan, plSelfie, verifyToken } from "../api/loan";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { getCustomer } from "../api/customer";
import { digiLockerResponse } from "../api/cashfree";
import Swal from "sweetalert2";

function ApplyNowInstantSelfie(props) {
  const { socket, logindata, setLogindata } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = location.search || location.pathname.split("?")[1];

  // Convert query string to a usable format
  const params = new URLSearchParams(queryString.replace(/\?/g, "&")); // Normalize query structure
  const id = params.get("id"); // Fetch `id`
  const token = params.get("token");
  const verificationId = params.get("verification_id");
  const [dataUri, setDataUri] = useState("");
  const [msg, setMsg] = useState("Upload");
  useEffect(() => {
    window.scrollTo(0, 0);
    navigate(
      `/apply-now/Instant/selfie?id=${id}${token ? `&token=${token}` : ``}${
        verificationId ? `&verification_id=${verificationId}` : ``
      }`,
      { replace: true }
    );
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const c_id = getLocalStorage("rv-user")?.id;
      if (c_id && token) {
        setMsg("Update");
        const response = await verifyToken({
          token: getLocalStorage("rv-user")?.accessToken,
          data: {
            loan_id: id,
            status: "Selfie Upload",
            token: token,
          },
        });
        if (response.status === 200 && response.data?.tokenValid) {
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You can't able to update the details. Your token is not valid.",
          });
          navigate("/instant-loan", { replace: true });
        }
      } else if (c_id && !token) {
        await getCust();
      }
    };
    asyncFn();
  }, [logindata]);

  const getCust = async () => {
    const checkCookie = getLocalStorage("rv-user");
    if (checkCookie) {
      const response = await getCustomer({
        customer_id: checkCookie.id,
        token: checkCookie.accessToken,
      });
      if (response.status === 200) {
        if (!token) {
          const payload = JSON.parse(response.data.aadhar_data);
          payload.loan_id = id;
          const res = await digiLockerResponse({
            data: payload,
            // data: { ...payload, customer_id: checkCookie.id, loan_id: id },
            token: checkCookie.accessToken,
          });
          if (res.status === 200) {
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went erong in your e-KYC.",
            });
            navigate(
              `/apply-now/Instant/selfie?id=${id}${
                token ? `&token=${token}` : ``
              }${verificationId ? `&verification_id=${verificationId}` : ``}`,
              { replace: true }
            );
          }
        }
      }
    }
  };

  async function saveData(e) {
    e.preventDefault();
    if (dataUri) {
      const response = await plSelfie({
        token: getLocalStorage("rv-user")?.accessToken,
        data: {
          loan_id: id,
          photo: dataUri,
        },
      });
      if (response.status === 200) {
        if (getLocalStorage("rv-user")?.id && id && token) {
          navigate("/", { replace: true });
        } else {
          navigate(`/apply-now/Instant/bank?id=${id}`, { replace: true });
        }
      }
    }
  }

  const ImagePreview = ({ dataUri }) => {
    return (
      <div className={"demo-image-preview "}>
        <img src={dataUri} />
      </div>
    );
  };

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Upload Selfie</center>
        </h5>
        <div class="contact-form pb-2">
          {dataUri ? (
            <>
              <ImagePreview dataUri={dataUri} />
              <center className="mt-30">
                <button
                  type="submit"
                  onClick={() => setDataUri("")}
                  class="default-btn disabled"
                  style={{ pointerEvents: "all", cursor: "pointer" }}
                >
                  Retake <span></span>
                </button>
                <button
                  type="submit"
                  onClick={saveData}
                  class="default-btn disabled"
                  style={{
                    pointerEvents: "all",
                    cursor: "pointer",
                    marginLeft: "30px",
                  }}
                >
                  {msg} <span></span>
                </button>
              </center>
            </>
          ) : (
            <Camera
              onTakePhoto={(dataUri) => {
                setDataUri(dataUri);
              }}
            />
          )}
        </div>
      </ApplyInstantComponent>
    </>
  );
}

export default ApplyNowInstantSelfie;
