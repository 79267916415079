import { useEffect, useState } from "react";
import ProfilePersonal from "../Components/profilePersonal";
import { useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import { validatePan, validateAadhar } from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { getCustomer } from "../api/customer";
import { panDetails } from "../api/customerAuth";
import Swal from "sweetalert2";

function ApplyNowInstantPan(props) {
  const { socket, logindata, setLogindata } = props;
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        await getCust(checkCookie.id, checkCookie.accessToken);
      }
    };
    asyncFn();
  }, [logindata]);

  const getCust = async (id, token) => {
    const response = await getCustomer({
      customer_id: id,
      token: token,
    });
    if (response.status === 200) {
      setData(response.data);
    }
  };

  function validatePanNo(str) {
    setErrors((errors) => ({
      ...errors,
      pan_no: validatePan(str).error,
    }));
  }
  function validateAadharNo(str) {
    setErrors((errors) => ({
      ...errors,
      aadhar_no: validateAadhar(str).error,
    }));
  }

  function validateForm() {
    validatePanNo(data.pan_no);
    validateAadharNo(data.aadhar_no)
    let valid = validatePan(data.pan_no).valid && validateAadhar(data.aadhar_no);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      const response = await panDetails({
        token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
        data: {
          id: data.id || getLocalStorage("rv-user")?.id,
          pan_no: data.pan_no,
          aadhar_no: data.aadhar_no
        },
      });
      if (response.status === 200) {
        navigate('/apply-now/Instant/Basics', { replace: true })
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h3>Get Instant Loan up to ₹50,000</h3>
        <p>Apply for an Instant Loan to fulfil all your financial needs</p>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <div class="form-group">
              <label className="label-login">PAN No: </label>
              <input
                type="text"
                name="pan_no"
                value={data.pan_no}
                class="form-control w-100"
                onBlur={() => validatePanNo(data.pan_no)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.pan_no && (
                <div class="help-block with-errors">{errors.pan_no}</div>
              )}
            </div>
            <div class="form-group">
              <label className="label-login">Aadhar No: </label>
              <input
                type="text"
                name="aadhar_no"
                value={data.aadhar_no}
                class="form-control w-100"
                onBlur={() => validateAadharNo(data.aadhar_no)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.aadhar_no && (
                <div class="help-block with-errors">{errors.aadhar_no}</div>
              )}
            </div>
          </div>
        </div>
        <button
          type="submit"
          onClick={saveData}
          class="default-btn disabled"
          style={{ pointerEvents: "all", cursor: "pointer" }}
        >
          Next <span></span>
        </button>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstantPan;
