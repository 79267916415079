import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import { updatePLStatus } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";

function ApplyNowInstant7(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleReviewLoan(status) {
    if (status === "success") {
      await updatePLStatus({
        token: getLocalStorage("rv-user").accessToken,
        data: {
          loan_id: id,
          status_name: "e-Sign",
          approved: 1
        }
      })
      navigate(`/apply-now/Instant/thanks?id=${id}`, { replace: true });
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h5><center>Review Your Loan Plan</center></h5>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ReviewLoan
              msg="Agree and Continue"
              onSubmit={handleReviewLoan}
              logindata={logindata}
              loandata={id}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant7;
