import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileBank from "../Components/profileBank";
import { checkInstantLoan, verifyToken } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import Swal from "sweetalert2";
function ApplyNowInstant6(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const token = query.get("token");

  const [msg, setMsg] = useState("Proceed");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  async function handleBankDetails(status) {
    if (status === "success") {
      if (getLocalStorage("rv-user")?.id && id && token) {
        navigate("/", { replace: true });
      } else {
        navigate(`/apply-now/Instant/bank-statement?id=${id}`, {
          replace: true,
        });
      }
    }
  }

  useEffect(() => {
    const asyncFn = async () => {
      const c_id = getLocalStorage("rv-user")?.id;
      if (c_id && id && token) {
        setMsg("Update");
        const response = await verifyToken({
          token: getLocalStorage("rv-user")?.accessToken,
          data: {
            loan_id: id,
            status: "Bank Details Verification",
            token: token,
          },
        });
        if (response.status === 200 && response.data?.tokenValid) {
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You can't able to update the details. Your token is not valid.",
          });
          navigate("/instant-loan", { replace: true });
        }
      } else if (c_id && !id && !token) {
        const response = await checkInstantLoan({
          token: getLocalStorage("rv-user")?.accessToken,
          data: {
            customer_id: c_id,
          },
        });
        if (
          response.status === 200 &&
          response?.data?.areDatesAtLeast30DaysApart
        ) {
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your previous loan was applied within 30 days! You can't not apply new instant loan now.",
          });
          navigate("/instant-loan", { replace: true });
        }
      } else if (c_id && id) {
        setMsg("Proceed");
      } else if (c_id && (id || token)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You can't able to update the details. Your link is not valid.",
        });
        navigate("/instant-loan", { replace: true });
      }
    };
    asyncFn();
  }, [logindata]);

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Bank Verification</center>
        </h5>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ProfileBank
              msg={msg}
              onSubmit={handleBankDetails}
              logindata={logindata}
              socket={socket}
              loan_id={id}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant6;
