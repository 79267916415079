import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import { applyInstantLoan } from "../api/loan";
import { validateSalary, validateSelect } from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import waiting from "../Assets/img/Banner/new/hourGlass.gif";
import doc from "../Assets/img/Banner/new/doc.gif";

function RequestLoan(props) {
  const navigate = useNavigate();
  const { msg, onSubmit, socket, setId } = props;
  const [data, setData] = useState({});
  const [amount, setAmount] = useState(null);
  const [purpose, setPurpose] = useState("");
  const [emi, setEmi] = useState(null);
  const [tenureOption, setTenureOption] = useState([]);
  const [selectedTenure, setSelectedTenure] = useState(null);
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const validateMaxAmount = (e) => {
    if (e.target.name === "amount") {
      let value = e.target.value;
      const max = 50000;
      if (Number(value) > Number(max)) {
        setAmount(max);
      }
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response.status === 200) {
          setData(response.data);
        }
      }
    };
    asyncFn();
  }, []);

  function validatePurpose(str) {
    setErrors((errors) => ({
      ...errors,
      purpose: validateSelect(str, "purpose").error,
    }));
  }

  function validateAmount(str) {
    setErrors((errors) => ({
      ...errors,
      amount: validateSalary(str, "amount").error,
    }));
  }

  function validateForm() {
    validateAmount(amount);
    validatePurpose(purpose);
    let valid =
      validateSalary(amount, "amount").valid &&
      validateSelect(purpose, "purpose").valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      const response = await applyInstantLoan({
        token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
        data: {
          customer_id: data.id || getLocalStorage("rv-user")?.id,
          req_amount: amount,
          purpose: purpose,
        },
      });
      if (response.status === 200) {
        setLoading(true);
        // toast.success("Your loan requested successfully.")
        // onSubmit('success');
        // navigate('/')
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your previous loan was applied within 30 days! You can't not apply new instant loan now.",
        });
      }
    }
  }

  useEffect(() => {
    socket.on("loanRes", (newObject) => {
      if (newObject.offered_amount) {
        setLoading(false);
        navigate(`/apply-now/Instant/offered?id=${newObject.id}`, {
          replace: true,
        });
      }
    });
  }, [socket]);

  return (
    <>
      {loading ? (
        <>
          <h3
            style={{
              marginTop: "-30px",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            <center> We're Processing On Your Request... </center>
          </h3>
          <div className="d-flex justify-content-center">
            <img
              src={waiting}
              alt="waiting"
              style={{ width: "100%", maxWidth: "150px", height: "auto" }}
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <div style={{ maxWidth: "400px", textAlign: "center" }}>
              It may takes few minutes to process your request. Please wait for
              few minutes. We will send you mail with offered details on your
              registered email
            </div>
          </div>
          {/* <div className='card eligibilityTable p-3 text-center'>Please wait for few minutes. Your request is <b>Processing...</b></div> */}
        </>
      ) : (
        <>
          <h3 style={{ marginTop: "-30px", marginBottom: "30px" }}>
            <center>Request for your Personal Loan</center>
          </h3>
          <div class="form-group">
            <label className="label-login">Amount</label>
            <input
              type="number"
              name="amount"
              class="form-control w-100"
              onBlur={(e) => validateMaxAmount(e)}
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
            {errors.amount && (
              <div class="help-block with-errors">{errors.amount}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Purpose </label>
            <select
              name="purpose"
              value={purpose}
              onChange={(e) => {
                setPurpose(e.target.value);
              }}
              class="form-control w-100"
              onBlur={() => validatePurpose(purpose)}
            >
              <option value="">SELECT PURPOSE</option>
              {[
                "PERSONAL USE",
                "MEDICAL EMERGENCIES",
                "EDUCATION FEES",
                "TRAVEL USE",
                "OTHER",
              ].map((o) => {
                return (
                  <option value={o} selected={purpose === o}>
                    {o}
                  </option>
                );
              })}
            </select>
            {errors.purpose && (
              <div class="help-block with-errors">{errors.purpose}</div>
            )}
          </div>
          <button
            type="submit"
            onClick={saveData}
            class="default-btn disabled"
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            {msg} <span></span>
          </button>
        </>
      )}
    </>
  );
}
export default RequestLoan;
