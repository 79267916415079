import BreadCrumbs from "../Components/breadcrumbs";
import { useEffect } from "react";
import smfc from "../Assets/img/Partners/smfg.png";
import creditvidya from "../Assets/img/Partners/creditvidya.png";
import birla from "../Assets/img/Partners/birla.png";
import indus from "../Assets/img/CC/indus.png";
import fibe from "../Assets/img/Partners/fibe.jpg";
import bajaj from "../Assets/img/Partners/bajaj.png";
import InstantLoanOptions from "../Components/instantLoanOptions";

function PersonalLoanFromBank() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Personal Loan",
    },
  ];

  const loanType = [
    {
      name: "IndusInd",
      icon: "fas fa-hand-holding-usd",
      path: "indusind",
      img: indus,
      desc: "",
      knowmore: "/instant/indusind",
      applyNow: "/apply-now/IndusIndPersonal",
    },
    // {
    //   name: "Aditya Birla",
    //   icon: "fas fa-business-time",
    //   path: "aditya-birla",
    //   img: birla,
    //   desc: "",
    //   knowmore: "/instant/aditya-birla",
    //   applyNow: "/apply-now/AdityaBirlaPersonal",
    // },
    {
      name: "SMFG",
      icon: "fas fa-user-graduate",
      path: "smfg",
      img: smfc,
      desc: "",
      knowmore: "/instant/smfg",
      applyNow: "/apply-now/SMFCPersonal",
    },
    {
      name: "Credit Vidya",
      icon: "fas fa-home",
      path: "credit-vidya",
      img: creditvidya,
      desc: "",
      knowmore: "/instant/credit-vidya",
      applyNow: "/apply-now/CreditVidyaPersonal",
    },
    {
      name: "Fibe",
      icon: "fas fa-home",
      path: "fibe",
      img: fibe,
      desc: "",
      knowmore: "/instant/fibe",
      applyNow: "/apply-now/FibePersonal",
    },
    {
      name: "Bajaj",
      icon: "fas fa-home",
      path: "bajaj",
      img: bajaj,
      desc: "",
      knowmore: "/instant/bajaj",
      applyNow: "/apply-now/BajajPersonal",
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <InstantLoanOptions data={loanType} type="Instant" />
    </>
  );
}
export default PersonalLoanFromBank;
